import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>放射温度計が活躍する世界</h2>
					<div className="content-container">
						<div className="office_inner">
							<div className="flex-container">
								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image"
											src="/asset/fw01.jpg"
											height="120"
											width="150"
											alt="
											電気設備の保守・点検
											"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">電気設備の保守・点検</p>
												<p>
													高圧配電盤、遮断機、開閉器、変圧器などの電気設備は、重要な接続部分の温度を定期的に測定することにより、過熱部分の早期発見ができ、事故を未然に防ぐことができます。
												</p>
											</li>
										</ul>
									</div>
								</div>

								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image"
											src="/asset/fw05.jpg"
											height="120"
											width="150"
											alt="
											食品の冷凍、解凍、保管温度管理
											
											"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">食品の冷凍、解凍、保管温度管理</p>
												<p>
													スーパーなどの生鮮食品の保管状態を温度測定することですばやくチェックすることができます。その他、冷凍食品の温度測定や解凍状態のチェックなどに活用できます。
												</p>
											</li>
										</ul>
									</div>
								</div>

								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image"
											src="/asset/fw02.jpg"
											height="120"
											width="150"
											alt="動物の健康管理"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">動物の健康管理</p>
												<p>
													豚、牛、馬などの家畜や実験動物の健康管理、繁殖期の保護・観察にも温度測定が取り入れられています。
												</p>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image2"
											src="/asset/fw03.jpg"
											height="143"
											width="220"
											alt="
											冷暖房器具の省エネルギー化"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">冷暖房器具の省エネルギー化</p>
												<p>
													建物の床、壁、天井や冷暖房器具などの温度測定により、断熱効果、冷暖房効果を知ることができます。
												</p>
											</li>
										</ul>
									</div>
								</div>

								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image2"
											src="/asset/fw04.jpg"
											height="143"
											width="220"
											alt="
											IC回路などの品質管理"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">IC回路などの品質管理</p>
												<p>
													回路基盤上の半導体やICなどの接合不良は多くの場合、熱が発生します。異常部分を早期発見したり、製造工程上の問題点を捜すためにも、温度管理は、ますます重視されるようになっています。
												</p>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image2"
											src="/asset/fw06.jpg"
											height="143"
											width="220"
											alt="
											成型加工"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">成型加工</p>
												<p>
													プラスチック、樹脂、ゴムの成型加工時の温度管理は、高品質な製品を生産するうえで重要なポイントになります。
												</p>
											</li>
										</ul>
									</div>
								</div>

								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image2"
											src="/asset/fw07.jpg"
											height="143"
											width="220"
											alt="
											焼き付け塗装の仕上がり管理"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">焼き付け塗装の仕上がり管理</p>
												<p>
													塗装の焼き付け時の温度を管理することにより、つねに均一の塗装に仕上げることが可能になります。適温の焼き付は塗装の剥離、ひび割れを防ぎ、仕上がりの美しい塗装ができます。
												</p>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image2"
											src="/asset/fw08.jpg"
											height="143"
											width="220"
											alt="加工食品の品質管理"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">加工食品の品質管理</p>
												<p>
													加工食品の分野でも、製造過程の温度管理により、味、香り、外観などの品質管理ができます。
													とくに、パン、クッキー、せんべいなどのように焼き加減が微妙な加工食品も、
													定期的に温度チェックを行い火加減をコントロールすることにより美味しく見える色に焼き上げることができます。
												</p>
											</li>
										</ul>
									</div>
								</div>

								<div className="temp02_flex1">
									<div className="text_align_center">
										<img
											className="temp08_image2"
											src="/asset/fw09.jpg"
											height="143"
											width="220"
											alt="配管設備の安全管理"
										/>
									</div>

									<div className="temp02_history_title">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex1">
												<p className="medium triangle_up triangle_color_green"></p>
											</li>
											<li className="temp03_flex2">
												<p className="medium">配管設備の安全管理</p>
												<p>
													高温及び低温ガス、液体を通す配管設備などのジョイント部の温度変化をチェックすることにより事故防止に役立っています。
												</p>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<PagingController current={8} next={'/support/documents/about-temperature/temp09'} prev={'/support/documents/about-temperature/temp07'} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
